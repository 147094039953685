import * as React from "react";
import { withPreviewResolver } from "gatsby-source-prismic";

const PreviewPage = ({ isPreview, isLoading }) => {
  if (isPreview === false) return "Not a preview!";

  return (
    <>
      <p>Loading</p>
    </>
  );
};

export default withPreviewResolver(PreviewPage, {
  repositoryName: "seekingstate",
  linkResolver: ({ node, key, value }) => doc => {
    // Your link resolver
    if (doc.type === "pamphlet") {
      return `/pamphlets-for-culture/${doc.uid}`;
    } else if (doc.type === "pamphlets_for_culture") {
      return `/pamphlets-for-culture`;
    } else if (doc.type === "events") {
      return `/events`;
    } else if (doc.type === "privacy_policy") {
      return `/privacy-policy`;
    } else if (doc.type === "homepage") {
      return `/`;
    }
  },
});
